<template>
    <v-row>
        <v-col
          md="10"
          offset-md="1"
          sm="12"
          xs="12"
        >
          <v-card
            elevation="2"
            class="user-card"
            :class="[!min ? 'flex' : '']"
          >
            <div class="user-container" 
              :class="[min ? 'min' : '', !linkDisabled ? 'link' : '']"
              @click="fioClickHandler(user.id)"
            >
              <div class="user-img">
                <v-img
                  max-height="150"
                  max-width="150"
                  min-height="150"
                  min-width="150"
                  fade
                  :src="avatarSrc"
                  alt="Специалист"
                  class="avatar"
                ></v-img>
              </div>
              <div class="user-info">
                <h5 
                  class="user-fio"
                  v-if="shortName(user)"
                >
                  {{user.surname}} {{user.name}} {{user.middleName}}
                </h5>
                <h6 
                  class="user-fio"
                  v-else
                >
                  {{user.surname}} {{user.name}} {{user.middleName}}
                </h6>
                <span v-for="(special, index) in user.specials" :key="index">
                  {{special}}<span v-if="index!==user.specials.length-1">,</span> 
                </span><br>
                <!-- <span class="user-price" v-if="user.minimalServicePrice">
                  Цена от 
                  <b>{{user.minimalServicePrice / 100}} руб.</b> 
                </span><br> -->
                <div v-if="user.userCategory">
                  <span class="user-category">
                    Категория - <b>{{user.userCategory}}</b>
                  </span><br>
                </div>
                <div v-if="user.workExperience">
                  <span class="user-exp">
                    Стаж - <b>{{getExp(user.workExperience)}}</b>
                  </span><br>
                </div>
                <div v-if="user.scientificAchievements">
                  <span class="user-ach">
                    <b>{{user.scientificAchievements}}</b>
                  </span><br>
                </div>
              </div>
            </div>
            <div 
              class="user-schedule"
              :class="[!min ? 'w40' : 'w100']"
            >
              <TimePicker 
                :freeIntervals="user.freeIntervals" 
                @time-selected="timeSelectedHandler(user.id)" 
                v-if="showIntervals && user.freeIntervals"
              />
            </div>
          </v-card>
        </v-col>
    </v-row>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import router from '../../router'

import TimePicker from './TimePicker.vue'

export default {
  created(){
    
  },
  components: {
    TimePicker,
  },
  props: {
    user: Object,
    // отключение ссылки при выборе конкретного пользователя
    linkDisabled: Boolean,
    showIntervals: Boolean,
    link: String,
  },
  computed: {
    min(){
      if (this.$mq === 'sm') return true
      return false
    },
    avatarSrc(){
      return window.config.apiUrl + '/file/get-user-photo?idUser=' + this.user.id
    },
  },
  methods: {
    // обработка выбора времени приема в тайм пикере
    timeSelectedHandler(userId){
      this.$store.commit('setUserId', userId)
      localStorage.userId = userId
      // переадресация на подтверждение записи
      router.push('/apply-appointment')
    },
    // получение стажа врача
    getExp(date){
      return  moment(date + '-12-31').fromNow().split(' ').splice(0,2).join(' ')
    },
    // обработка выбора конкретного врача
    fioClickHandler(id){
      // если ссылка отключена (при отображении конкретного врача)
      if (this.linkDisabled) return
      // установка текущего пользователя в сторе
      this.$store.commit('setUserId', id)
      localStorage.userId = id
      // переадресация на страницу текущего врача
      router.push(this.link)
    },
    shortName(user){
      return (
        user.surname?.length < 13 
        && user.name?.length < 13 
        && user.middleName?.length < 13
        )
    }
  }
}
</script>

<style scoped>
  .user-card{
    padding: 10px;
  }
  .user-card.flex{
    display: flex;
    justify-content: space-between;
  }
  .user-container{
    max-width: 60%;
    padding: 5px;
    display: flex;
  }
  .user-container.min span{
    font-size: 00.9rem;
  }
  .user-img{
    width: 150px;
    height: 150px;
    margin-right: 15px;
  }
  .user-img img{
    width: 150px;
  }
  .user-img .avatar{
    border-radius: 4px;
  }
  .user-info{
    max-width: 90%;
  }
  .user-schedule.w40{
    width: 40%;
  }
  .user-schedule.w100{
    width: 100%;
    padding-right: 15px;
  }
  .link{
    cursor: pointer;
  }
  .link .user-fio:hover{
    color: var(--primary-color);
  }
</style>